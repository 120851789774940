// File: frontend/src/components/users/EditUser.js

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../axiosConfig';
import './EditUser.css';

const EditUser = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [roles, setRoles] = useState([]);

    const fetchUser = useCallback(async () => {
        try {
            const response = await axiosInstance.get(`users/${userId}/`);
            const user = response.data;
            setUsername(user.username);
            setEmail(user.email);
            setRole(user.role ? user.role.id : '');
        } catch (error) {
            console.error('There was an error fetching the user!', error);
            if (error.response && error.response.status === 401) {
                navigate('/login');
            }
        }
    }, [userId, navigate]);

    const fetchRoles = useCallback(async () => {
        try {
            const response = await axiosInstance.get('roles/');
            setRoles(response.data);
        } catch (error) {
            console.error('There was an error fetching the roles!', error);
            if (error.response && error.response.status === 401) {
                navigate('/login');
            }
        }
    }, [navigate]);

    useEffect(() => {
        fetchUser();
        fetchRoles();
    }, [fetchUser, fetchRoles]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axiosInstance.put(`users/${userId}/`, {
                username,
                email,
                role_id: role
            });
            navigate('/users');
        } catch (error) {
            console.error('There was an error updating the user!', error);
            if (error.response && error.response.status === 401) {
                navigate('/login');
            }
        }
    };

    return (
        <div>
            <h1>Edit User</h1>
            <form onSubmit={handleSubmit}>
                <input 
                    type="text" 
                    placeholder="Username" 
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                />
                <input 
                    type="email" 
                    placeholder="Email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                />
                <select value={role} onChange={(e) => setRole(e.target.value)}>
                    <option value="">Select Role</option>
                    {roles.map((r) => (
                        <option key={r.id} value={r.id}>{r.name}</option>
                    ))}
                </select>
                <button type="submit">Update User</button>
            </form>
        </div>
    );
};

export default EditUser;