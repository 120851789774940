// /components/settings/Settings.js

import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import SiteConfiguration from './SiteConfiguration';
import Appearance from './Appearance';

const Settings = () => {
  return (
    <div className="settings">
      <h1>Settings</h1>
      <nav>
        <ul>
          <li><Link to="/settings/site-configuration">Site Configuration</Link></li>
          <li><Link to="/settings/appearance">Appearance</Link></li>
        </ul>
      </nav>

      <Routes>
        <Route path="/site-configuration" element={<SiteConfiguration />} />
        <Route path="/appearance/*" element={<Appearance />} />
      </Routes>
    </div>
  );
};

export default Settings;