// File: frontend/src/components/Header.js

import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { setPublishedPages } from '../redux/slices/publishedPagesSlice';
import { useSelector, useDispatch } from 'react-redux';
import axiosInstance from '../axiosConfig';
import { logout, fetchUserDetails } from '../redux/slices/userSlice';
import styles from './Header.module.css';

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, token } = useSelector((state) => state.user);
  const publishedPages = useSelector((state) => state.publishedPages);
  const [isNavVisible, setIsNavVisible] = useState(false);

  console.log('User state in Header:', user);
  console.log('Token in Header:', token);

  useEffect(() => {
    if (token && !user) {
      dispatch(fetchUserDetails());
    }
  }, [token, user, dispatch]);


  const fetchPages = useCallback(async () => {
    try {
        const response = await axiosInstance.get('pages/');
        const sortedPages = response.data.sort((a, b) => a.menu_position - b.menu_position);
        const sortedPublishedPages = sortedPages.filter(page => page.is_published);
        dispatch(setPublishedPages(sortedPublishedPages));
    } catch (error) {
        console.error("There was an error fetching the pages!", error);
        if (error.response && error.response.status === 401) {
          dispatch(logout());
          navigate('/login');
        }
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    fetchPages();
  }, [fetchPages]);

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <div className={styles.welcomeMessage}>
          {user && user.username ? (
            <h3>Welcome, {user.username}!</h3>
          ) : token ? (
            <h3>Welcome, User!</h3>
          ) : (
            <h3>Welcome, Guest!</h3>
          )}
        </div>
        <div className={styles.mainMenu}>
          <button className={styles.navToggle} onClick={toggleNav}>
            ☰
          </button>
          <nav className={`${styles.nav} ${isNavVisible ? styles.navVisible : ''}`}>
            <ul className={styles.navList}>
              <li className={styles.navItem}>
                <Link to="/" className={styles.navLink} onClick={() => setIsNavVisible(false)}>
                  Home
                </Link>
              </li>
              {publishedPages.map(page => (
                <li key={page.id} className={styles.navItem}>
                  <Link to={`/page/${page.id}`} className={styles.navLink} onClick={() => setIsNavVisible(false)}>
                    {page.title}
                  </Link>
                </li>
              ))}
              {user ? (
                <>
                  <li className={styles.navItem}>
                    <Link to="/dashboard" className={styles.navLink} onClick={() => setIsNavVisible(false)}>
                      Dashboard
                    </Link>
                  </li>
                  <li className={styles.navItem}>
                    <Link onClick={() => { handleLogout(); setIsNavVisible(false); }} to="/" className={styles.navButton}>
                      Logout
                    </Link>
                  </li>
                </>
              ) : (
                <li className={styles.navItem}>
                  <Link to="/login" className={styles.navLink} onClick={() => setIsNavVisible(false)}>
                    Login
                  </Link>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;