// Folder: frontend/src/components/blogs
// File: CreateSubBlog.js

import React from 'react';

const CreateSubBlog = () => {
    return (
        <div>
            <h1>Create SubBlog</h1>
            {/* Add your content here */}
        </div>
    );
};

export default CreateSubBlog;
