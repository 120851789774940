import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axiosInstance from '../../axiosConfig';
import { Link } from 'react-router-dom';
import { setPublishedPages, addPublishedPage, removePublishedPage } from '../../redux/slices/publishedPagesSlice';
import './ListPages.css';

const ListPages = () => {
    const [pages, setPages] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchPages = async () => {
            try {
                const response = await axiosInstance.get('pages/');
                setPages(response.data);
                const publishedPages = response.data.filter(page => page.is_published);
                dispatch(setPublishedPages(publishedPages));
            } catch (error) {
                console.error("There was an error fetching the pages!", error);
            }
        };

        fetchPages();
    }, [dispatch]);

    const handleToggleStatus = async (id) => {
        const page = pages.find(page => page.id === id);
        try {
            const response = await axiosInstance.patch(`pages/${id}/`, { is_published: !page.is_published });
            console.log('Response:', response.data);
            setPages(pages.map(p => p.id === id ? response.data : p));
            if (response.data.is_published) {
                dispatch(addPublishedPage(response.data));
            } else {
                dispatch(removePublishedPage(id));
            }
        } catch (error) {
            console.error("There was an error updating the page status!", error);
            console.error("Error response:", error.response?.data);
        }
    };

    const handleDeletePage = async (id) => {
        try {
            await axiosInstance.delete(`pages/${id}/`);
            setPages(pages.filter(page => page.id !== id));
        } catch (error) {
            console.error("There was an error deleting the page!", error);
        }
    };

    const handleMenuPositionChange = async (id, newPosition) => {
        try {
            const response = await axiosInstance.patch(`pages/${id}/`, { menu_position: newPosition });
            setPages(prevPages => prevPages.map(p => p.id === id ? response.data : p));
        } catch (error) {
            console.error("There was an error updating the menu position!", error);
        }
    };

    return (
        <div>
            <h1>Pages</h1>
            <Link to="/create-page" className="btn btn-primary">Create New Page (+)</Link>
            <table>
                <thead>
                    <tr>
                        <th>Titles</th>
                        <th>Page Status</th>
                        <th>Menu Position</th>
                        <th>Created Date</th>
                        <th>Last Modified Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {pages.map(page => (
                        <tr key={page.id}>
                            <td>{page.title}</td>
                            <td>
                                <label className="switch">
                                    <input 
                                        type="checkbox" 
                                        checked={page.is_published} 
                                        onChange={() => handleToggleStatus(page.id)} 
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={page.menu_position}
                                    onChange={(e) => handleMenuPositionChange(page.id, e.target.value)}
                                    min="0"
                                    style={{ width: '50px' }}
                                />
                            </td>
                            <td>{new Date(page.created_at).toLocaleDateString()}</td>
                            <td>{new Date(page.updated_at).toLocaleDateString()}</td>
                            <td>
                                <Link to={`/edit-page/${page.id}`} className="btn btn-secondary" style={{ padding: '5px' }}>Edit</Link>
                                <button onClick={() => handleDeletePage(page.id)} className="btn btn-danger">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ListPages;
