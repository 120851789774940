// File: frontend/src/components/blogs/ViewBlog.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosConfig';

const ViewBlog = () => {
    const [blog, setBlog] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await axiosInstance.get(`blogs/${id}/`);
                if (response.data.is_published || response.data.is_published === undefined) {
                    setBlog(response.data);
                } else {
                    navigate('/'); // Redirect to home if blog is not published
                }
            } catch (error) {
                console.error("Error fetching blog:", error);
                navigate('/');
            }
        };

        fetchBlog();
    }, [id, navigate]);

    if (!blog) {
        return <div>Loading...</div>;
    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <div>
   <h1 className="view-blog-title">
  {blog.title} - {formatDate(blog.created_at)}
</h1>
          
            <div className="view-blog-container" dangerouslySetInnerHTML={{ __html: blog.content }} />
        </div>
    );
};

export default ViewBlog;
