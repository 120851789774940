import axios from 'axios';
import { store } from './redux/store';
import { refreshToken, logout } from './redux/slices/userSlice';

const axiosInstance = axios.create({
    baseURL: 'https://yildiz.in/api/',
    withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshResult = await store.dispatch(refreshToken()).unwrap();
        axiosInstance.defaults.headers['Authorization'] = `Bearer ${refreshResult.access}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        store.dispatch(logout());
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;