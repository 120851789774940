// Folder: frontend/src/redux/slices
// File: pageSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pages: [],
};

const pageSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    setPages(state, action) {
      state.pages = action.payload;
    },
    addPage(state, action) {
      state.pages.push(action.payload);
    },
    removePage(state, action) {
      state.pages = state.pages.filter(page => page.id !== action.payload);
    },
  },
});

export const { setPages, addPage, removePage } = pageSlice.actions;

export default pageSlice.reducer;
