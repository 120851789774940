// Folder: frontend/src/components/users
// File: CreateUser.js

import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosConfig';
import './CreateUser.css';

const CreateUser = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        axiosInstance.get('roles/')
            .then(response => {
                setRoles(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the roles!', error);
                setErrorMessage('Error fetching roles. Please refresh the page.');
            });
    }, []);

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!username || !email || !password || !role) {
          setErrorMessage('Please fill in all fields');
          return;
      }
      setLoading(true);
      setErrorMessage('');
      setSuccessMessage('');
      const userData = {
          username,
          email,
          password,
          role_id: parseInt(role)
      };
      try {
          await axiosInstance.post('create-user/', userData);
          setSuccessMessage('User created successfully');
          setUsername('');
          setEmail('');
          setPassword('');
          setRole('');
      } catch (error) {
          console.error('Error response:', error.response?.data);
          setErrorMessage(error.response?.data?.error || 'Error creating user. Please try again.');
      } finally {
          setLoading(false);
      }
    };

    return (
        <div className="create-user-container">
            <h1>Create User</h1>
            {successMessage && <p className="success-message">{successMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <form onSubmit={handleSubmit}>
                <input 
                    type="text" 
                    placeholder="Username" 
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                    required
                />
                <input 
                    type="email" 
                    placeholder="Email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    required
                />
                <input 
                    type="password" 
                    placeholder="Password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    required
                />
                <select 
                    value={role} 
                    onChange={(e) => setRole(e.target.value)}
                    required
                >
                    <option value="">Select Role</option>
                    {roles.map((r) => (
                        <option key={r.id} value={r.id}>{r.name}</option>
                    ))}
                </select>
                <button type="submit" disabled={loading}>
                    {loading ? 'Creating...' : 'Create User'}
                </button>
            </form>
        </div>
    );
};

export default CreateUser;
