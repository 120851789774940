// File: frontend/src/components/Dashboard.js

import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Dashboard.module.css';

const Dashboard = () => {
    return (
  
            <div className={styles.mainMenu}>
                <div className={styles.dashboardHeader}>
                    <h1>Dashboard</h1>
                </div>
                <div className={styles.twoColumnLayout}>
                    <div className={styles.column}>
                        <h2>Content Management</h2>
                        <ul className={styles.navList}>
                            <li className={styles.navItem}>
                                <Link to="/pages" className={styles.navLink}>List Pages</Link>
                            </li>
                            <li className={styles.navItem}>
                                <Link to="/create-page" className={styles.navLink}>Create Page</Link>
                            </li>
                            <li className={styles.navItem}>
                                <Link to="/create-subpage" className={styles.navLink}>Create SubPage</Link>
                            </li>
                            <li className={styles.navItem}>
                                <Link to="/blogs" className={styles.navLink}>List Blogs</Link>
                            </li>
                            <li className={styles.navItem}>
                                <Link to="/create-blog" className={styles.navLink}>Create Blog</Link>
                            </li>
                            <li className={styles.navItem}>
                                <Link to="/create-subblog" className={styles.navLink}>Create SubBlog</Link>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.column}>
                        <h2>User/Role Management</h2>
                        <ul className={styles.navList}>
                            <li className={styles.navItem}>
                                <Link to="/users" className={styles.navLink}>User List</Link>
                            </li>
                            <li className={styles.navItem}>
                                <Link to="/create-user" className={styles.navLink}>Create User</Link>
                            </li>
                            <li className={styles.navItem}>
                                <Link to="/roles" className={styles.navLink}>Role List</Link>
                            </li>
                            <li className={styles.navItem}>
                                <Link to="/create-role" className={styles.navLink}>Create Role</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
 
    );
}

export default Dashboard;