// File: frontend/src/components/blogs/BlogMainPage.js

import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../axiosConfig';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';

import './BlogMainPage.css';

const BlogMainPage = () => {
    const [blogs, setBlogs] = useState([]);

    const fetchBlogs = useCallback(async () => {
        try {
            const response = await axiosInstance.get('blogs/');
            const publishedBlogs = response.data.filter(blog => blog.is_published);
            const sortedBlogs = publishedBlogs.sort((a, b) => (a.order || 0) - (b.order || 0));
            setBlogs(sortedBlogs);
        } catch (error) {
            console.error("There was an error fetching the blogs!", error);
        }
    }, []);

    useEffect(() => {
        fetchBlogs();
    }, [fetchBlogs]);

    const createMarkup = (html) => {
        return { __html: DOMPurify.sanitize(html) };
    };

    const getFirstImage = (content) => {
        const div = document.createElement('div');
        div.innerHTML = content;
        const img = div.querySelector('img');
        return img ? img.src : null;
    };

    const getFirst30Words = (content) => {
        const div = document.createElement('div');
        div.innerHTML = content;
        const text = div.textContent || div.innerText;
        return text.split(' ').slice(0, 30).join(' ') + '...';
    };

    // Split blogs into two arrays for two columns
    const leftColumnBlogs = blogs.filter((_, index) => index % 2 === 0);
    const rightColumnBlogs = blogs.filter((_, index) => index % 2 !== 0);

    return (
        <div>
            <h1>Latest Blogs</h1>

            <div className="view-page-container two-column-layout">
                <div className="column">
                    {leftColumnBlogs.map(blog => (
                        <div key={blog.id} className="blog-summary">
                            <h2>{blog.title}</h2>
                            <p className="blog-created-date">Created on: {new Date(blog.created_at).toLocaleDateString()}</p>
                            {getFirstImage(blog.content) && (
                                <img src={getFirstImage(blog.content)} alt={blog.title} className="blog-preview-image" />
                            )}
                            <div className="blog-summary" dangerouslySetInnerHTML={createMarkup(getFirst30Words(blog.content))} />
                            <Link to={`/blog/${blog.id}`}>Read more</Link>
                        </div>
                    ))}
                </div>
                <div className="column">
                    {rightColumnBlogs.map(blog => (
                        <div key={blog.id} className="blog-summary">
                            <h2>{blog.title}</h2>
                            <p className="blog-created-date">Created on: {new Date(blog.created_at).toLocaleDateString()}</p>
                            {getFirstImage(blog.content) && (
                                <img src={getFirstImage(blog.content)} alt={blog.title} className="blog-preview-image" />
                            )}
                            <div className="blog-summary" dangerouslySetInnerHTML={createMarkup(getFirst30Words(blog.content))} />
                            <Link to={`/blog/${blog.id}`}>Read more</Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BlogMainPage;