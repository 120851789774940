// File: frontend/src/components/blogs/EditBlog.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosConfig';
import Editor from '../common/Editor';

const EditBlog = ({ fetchBlogs }) => {
    const { id } = useParams();
    const [blogData, setBlogData] = useState(null);
    const [createdDate, setCreatedDate] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await axiosInstance.get(`blogs/${id}/`);
                setBlogData(response.data);
                setCreatedDate(response.data.created_at ? new Date(response.data.created_at).toISOString().slice(0, -8) : '');
            } catch (error) {
                console.error('Error fetching blog:', error);
                if (error.response) {
                    console.error("Data:", error.response.data);
                    console.error("Status:", error.response.status);
                    console.error("Headers:", error.response.headers);
                    if (error.response.status === 401) {
                        navigate('/login');
                    } else if (error.response.status === 404) {
                        alert('Blog not found.');
                        navigate('/blogs');
                    }
                } else if (error.request) {
                    console.error("Request:", error.request);
                    alert('No response received from server.');
                } else {
                    console.error('Error:', error.message);
                    alert('Error fetching blog data.');
                }
            }
        };

        fetchBlog();
    }, [id, navigate]);

    const handleSave = async (title, content) => {
        const data = {
            title: title,
            content: content,
            created_at: createdDate || new Date().toISOString(),
        };

        try {
            const response = await axiosInstance.put(`blogs/${id}/`, data);
            if (response.status === 200) {
                alert('Blog updated!');
                if (typeof fetchBlogs === 'function') {
                    fetchBlogs();
                }
                navigate('/blogs');
            } else {
                alert('Error updating blog.');
            }
        } catch (error) {
            console.error('Error updating blog:', error);
            if (error.response) {
                console.error("Data:", error.response.data);
                console.error("Status:", error.response.status);
                console.error("Headers:", error.response.headers);
                if (error.response.status === 401) {
                    navigate('/login');
                }
            } else if (error.request) {
                console.error("Request:", error.request);
                alert('No response received from server.');
            } else {
                console.error('Error:', error.message);
                alert('Error updating blog.');
            }
        }
    };

    if (!blogData) {
        return <div>Loading...</div>;
    }

    return (
        <div className="edit-blog-container">
            <h1>Edit Blog</h1>
            <input
                type="datetime-local"
                value={createdDate}
                onChange={(e) => setCreatedDate(e.target.value)}
            />
            <Editor
                key={blogData.id}
                initialTitle={blogData.title}
                initialContent={blogData.content || ''}
                onSave={handleSave}
                allowImageResize={true}
            />
        </div>
    );
};

export default EditBlog;