// File: frontend/src/components/pages/EditPage.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axiosInstance from '../../axiosConfig';
import Editor from '../common/Editor';
import { logout } from '../../redux/slices/userSlice';

const EditPage = ({ fetchPages }) => {
    const { id } = useParams();
    const [pageData, setPageData] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchPage = async () => {
            try {
                const response = await axiosInstance.get(`pages/${id}/`);
                setPageData(response.data);
            } catch (error) {
                console.error('Error fetching page:', error);
                if (error.response && error.response.status === 401) {
                    dispatch(logout());
                    navigate('/login');
                } else {
                    alert('Error fetching page data.');
                }
            }
        };

        fetchPage();
    }, [id, dispatch, navigate]);

    const handleSave = async (title, content) => {
        const userId = localStorage.getItem('user_id');

        if (!userId) {
            alert("User not authenticated.");
            return;
        }

        const data = {
            title: title,
            content: content,
            author: userId,
        };

        try {
            const response = await axiosInstance.put(`pages/${id}/`, data);
            if (response.status === 200) {
                alert('Content updated!');
                if (typeof fetchPages === 'function') {
                    fetchPages();
                }
                navigate('/pages');
            } else {
                alert('Error updating content.');
            }
        } catch (error) {
            console.error('Error:', error.response?.data || error.message);
            if (error.response && error.response.status === 401) {
                dispatch(logout());
                navigate('/login');
            } else {
                alert('Error updating content.');
            }
        }
    };

    if (!pageData) {
        return <div>Loading...</div>;
    }

    return (
        <div className="edit-page-container">
            <h1>Edit Page</h1>
            <Editor 
                key={pageData.id}
                initialTitle={pageData.title} 
                initialContent={pageData.content || ''} // Ensure content is never undefined
                onSave={handleSave} 
            />
        </div>
    );
};

export default EditPage;