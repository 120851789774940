// File: frontend/src/redux/slices/userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../axiosConfig';

export const login = createAsyncThunk(
  'user/login',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('token/', userData);
      localStorage.setItem('access_token', response.data.access);
      localStorage.setItem('refresh_token', response.data.refresh);
      
      axiosInstance.defaults.headers['Authorization'] = `Bearer ${response.data.access}`;
      
      return { token: response.data.access, user: response.data.user };
    } catch (error) {
      console.error('Login error:', error.response || error);
      return rejectWithValue(error.response?.data?.error || 'An error occurred during login');
    }
  }
);

export const fetchUserDetails = createAsyncThunk(
  'user/fetchUserDetails',
  async (_, { getState, rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('users/me/');
      return response.data;
    } catch (error) {
      console.error('Error fetching user details:', error);
      return rejectWithValue('Failed to fetch user details');
    }
  }
);

export const refreshToken = createAsyncThunk(
  'user/refreshToken',
  async (_, { rejectWithValue }) => {
    try {
      const refresh = localStorage.getItem('refresh_token');
      const response = await axiosInstance.post('token/refresh/', { refresh });
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to refresh token');
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    token: null,
    status: null,
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    },
    clearUser: (state) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.token = action.payload.token;
        state.user = action.payload.user;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.token = action.payload.access;
        localStorage.setItem('access_token', action.payload.access);
      });
  },
});

export const { logout, clearUser, setToken } = userSlice.actions;
export default userSlice.reducer;