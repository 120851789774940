// Folder: frontend/src/redux
// File: store.js

import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import userReducer from './slices/userSlice';
import publishedPagesReducer from './slices/publishedPagesSlice';
import pageReducer from './slices/pageSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'publishedPages']
};

const rootReducer = combineReducers({
  user: userReducer,
  publishedPages: publishedPagesReducer,
  pages: pageReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);