// /components/settings/ThemeSelection.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosConfig';

const ThemeSelection = () => {
  const [themes, setThemes] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState('');

  useEffect(() => {
    fetchThemes();
  }, []);

  const fetchThemes = async () => {
    try {
      const response = await axiosInstance.get('/themes');
      setThemes(response.data);
      const currentTheme = await axiosInstance.get('/settings/current-theme');
      setSelectedTheme(currentTheme.data.theme);
    } catch (error) {
      console.error('Error fetching themes:', error);
    }
  };

  const handleThemeChange = async (e) => {
    const newTheme = e.target.value;
    setSelectedTheme(newTheme);
    try {
      await axiosInstance.put('/settings/current-theme', { theme: newTheme });
      alert('Theme updated successfully!');
    } catch (error) {
      console.error('Error updating theme:', error);
      alert('Failed to update theme. Please try again.');
    }
  };

  return (
    <div className="theme-selection">
      <h3>Theme Selection</h3>
      <select value={selectedTheme} onChange={handleThemeChange}>
        {themes.map(theme => (
          <option key={theme.id} value={theme.id}>{theme.name}</option>
        ))}
      </select>
    </div>
  );
};

export default ThemeSelection;