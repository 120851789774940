// /frontend/src/components/common/EditorToolbar.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBold, faItalic, faUnderline, faListOl, faListUl, faLink, faUnlink,
    faAlignLeft, faAlignCenter, faAlignRight, faAlignJustify, faIndent,
    faOutdent, faTable, faFont, faImage, faUndo, faRedo, faTrash,
    faTextWidth, faHeading, faTextHeight, faObjectGroup, faObjectUngroup,
    faFileImage, faTextSlash, faFloppyDisk
} from '@fortawesome/free-solid-svg-icons';


const EditorToolbar = ({ 
    execCmd, 
    wrapTextAroundImage, 
    uploadImage, 
    insertTable, 
    setLineHeight,
    editorRef,
    undo,
    redo,
    selectedImage,
    imageWidth,
    imageHeight,
    setImageWidth,
    setImageHeight,
    handleImageResize,
    deleteSelectedImage,
    setLetterSpacing,  // Add this prop
    handleSave  // Add this prop


}) => {


    return (
        <div className="toolbar">
            <button onClick={undo} title="Undo">
                <FontAwesomeIcon icon={faUndo} />
            </button>
            <button onClick={redo} title="Redo">
                <FontAwesomeIcon icon={faRedo} />
            </button>
            <button onClick={() => wrapTextAroundImage('left')} title="Align Image Left">
                <FontAwesomeIcon icon={faObjectUngroup} />
            </button>
            <button onClick={() => wrapTextAroundImage('right')} title="Align Image Right">
                <FontAwesomeIcon icon={faObjectGroup} />
            </button>
            <button onClick={() => execCmd('indent')} title="Indent">
                <FontAwesomeIcon icon={faIndent} />
            </button>
            <button onClick={() => execCmd('outdent')} title="Outdent">
                <FontAwesomeIcon icon={faOutdent} />
            </button>
            <button onClick={() => execCmd('bold')} title="Bold">
                <FontAwesomeIcon icon={faBold} />
            </button>
            <button onClick={() => execCmd('italic')} title="Italic">
                <FontAwesomeIcon icon={faItalic} />
            </button>
            <button onClick={() => execCmd('underline')} title="Underline">
                <FontAwesomeIcon icon={faUnderline} />
            </button>
            <button onClick={() => execCmd('insertOrderedList')} title="Ordered List">
                <FontAwesomeIcon icon={faListOl} />
            </button>
            <button onClick={() => execCmd('insertUnorderedList')} title="Unordered List">
                <FontAwesomeIcon icon={faListUl} />
            </button>
            <button onClick={() => execCmd('createLink', prompt('Enter the URL', 'http://'))} title="Link">
                <FontAwesomeIcon icon={faLink} />
            </button>
            <button onClick={() => execCmd('unlink')} title="Remove Link">
                <FontAwesomeIcon icon={faUnlink} />
            </button>
            <button onClick={() => execCmd('justifyLeft')} title="Align Left">
                <FontAwesomeIcon icon={faAlignLeft} />
            </button>
            <button onClick={() => execCmd('justifyCenter')} title="Align Center">
                <FontAwesomeIcon icon={faAlignCenter} />
            </button>
            <button onClick={() => execCmd('justifyRight')} title="Align Right">
                <FontAwesomeIcon icon={faAlignRight} />
            </button>
            <button onClick={() => execCmd('justifyFull')} title="Justify">
                <FontAwesomeIcon icon={faAlignJustify} />
            </button>
            <input type="color" onChange={(e) => execCmd('foreColor', e.target.value)} title="Text Color"/>
            <button title="Upload Image" className="icon-button">
                <label htmlFor="imageUpload" className="image-upload-label">
                    <FontAwesomeIcon icon={faFileImage} />
                </label>
                <input 
                    type="file" 
                    id="imageUpload" 
                    onChange={uploadImage} 
                    title="Upload Image" 
                    style={{ display: 'none' }} 
                />
            </button>
            <button onClick={insertTable} title="Insert Table">
                <FontAwesomeIcon icon={faTable} />
            </button>
            <div className="dropdown">
                <button className="dropbtn" title="Font Family">
                    <FontAwesomeIcon icon={faFont} />
                </button>
                <div className="dropdown-content">
                    <button onClick={() => execCmd('fontName', 'Arial')}>Arial</button>
                    <button onClick={() => execCmd('fontName', 'Times New Roman')}>Times New Roman</button>
                    <button onClick={() => execCmd('fontName', 'Georgia')}>Georgia</button>
                    <button onClick={() => execCmd('fontName', 'Chartere')}>Chartere</button>
                    <button onClick={() => execCmd('fontName', 'Libre Baskerville')}>Libre Baskerville</button>
                    <button onClick={() => execCmd('fontName', 'Caveat')}>Caveat</button>
                    <button onClick={() => execCmd('fontName', 'Shadows Into Light')}>Shadows Into Light</button>
                </div>
            </div>
            <div className="dropdown">
                <button className="dropbtn" title="Font Size">
                    <FontAwesomeIcon icon={faTextWidth} />
                </button>
                <div className="dropdown-content">
                    <button onClick={() => execCmd('fontSize', '1')}>10px</button>
                    <button onClick={() => execCmd('fontSize', '2')}>13px</button>
                    <button onClick={() => execCmd('fontSize', '3')}>16px</button>
                    <button onClick={() => execCmd('fontSize', '4')}>18px</button>
                    <button onClick={() => execCmd('fontSize', '5')}>24px</button>
                    <button onClick={() => execCmd('fontSize', '6')}>32px</button>
                    <button onClick={() => execCmd('fontSize', '7')}>48px</button>
                </div>
            </div>
            
            <div className="dropdown">
            <button className="dropbtn" title="Line Height">
                <FontAwesomeIcon icon={faTextHeight} />
            </button>
            <div className="dropdown-content">
                <button onClick={() => setLineHeight('normal')}>Normal</button>
                <button onClick={() => setLineHeight('1')}>1</button>
                <button onClick={() => setLineHeight('1.5')}>1.5</button>
                <button onClick={() => setLineHeight('2')}>2</button>
            </div>
        </div>

            <div className="dropdown">
                <button className="dropbtn" title="Heading">
                    <FontAwesomeIcon icon={faHeading} />
                </button>
                <div className="dropdown-content">
                    <button onClick={() => execCmd('formatBlock', '<h1>')}>H1</button>
                    <button onClick={() => execCmd('formatBlock', '<h2>')}>H2</button>
                    <button onClick={() => execCmd('formatBlock', '<h3>')}>H3</button>
                    <button onClick={() => execCmd('formatBlock', '<h4>')}>H4</button>
                    <button onClick={() => execCmd('formatBlock', '<h5>')}>H5</button>
                    <button onClick={() => execCmd('formatBlock', '<h6>')}>H6</button>
                </div>
            </div>

            <div className="dropdown">
                <button className="dropbtn" title="Letter Spacing">
                    <FontAwesomeIcon icon={faTextSlash} />
                </button>
                <div className="dropdown-content">
                    <button onClick={() => setLetterSpacing('normal')}>Normal</button>
                    <button onClick={() => setLetterSpacing('0.05em')}>Slight</button>
                    <button onClick={() => setLetterSpacing('0.1em')}>Medium</button>
                    <button onClick={() => setLetterSpacing('0.15em')}>Wide</button>
                </div>
            </div>

            {selectedImage && (
                <div className="image-resize-controls">
                        <input 
                            type="number" 
                            value={imageWidth} 
                            onChange={(e) => setImageWidth(e.target.value)}
                            placeholder="W"
                            className="resize-input"
                        />
                        <input 
                            type="number" 
                            value={imageHeight} 
                            onChange={(e) => setImageHeight(e.target.value)}
                            placeholder="H"
                            className="resize-input"
                        />
                    <button onClick={handleImageResize} title="Resize Image">
                        <FontAwesomeIcon icon={faImage} />
                    </button>
                    <button onClick={deleteSelectedImage} title="Delete Image">
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>

                
            )}


            <div className="spacer"></div>
       
<button className="save-button" onClick={handleSave} title="Save">
                <FontAwesomeIcon icon={faFloppyDisk} />
            </button>
          

        </div>
    );
};

export default EditorToolbar;