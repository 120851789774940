// Folder: frontend/src/components/pages
// File: CreateSubPage.js

import React from 'react';

const CreateSubPage = () => {
    return (
        <div>
            <h1>Create SubPage</h1>
            {/* Add your content here */}
        </div>
    );
};

export default CreateSubPage;
