// Folder: frontend/src/redux/slices
// File: publishedPagesSlice.js

import { createSlice } from '@reduxjs/toolkit';

const publishedPagesSlice = createSlice({
  name: 'publishedPages',
  initialState: [],
  reducers: {
    setPublishedPages: (state, action) => {
      return action.payload;
    },
    addPublishedPage: (state, action) => {
      state.push(action.payload);
    },
    removePublishedPage: (state, action) => {
      return state.filter(page => page.id !== action.payload);
    },
  },
});

export const { setPublishedPages, addPublishedPage, removePublishedPage } = publishedPagesSlice.actions;

export default publishedPagesSlice.reducer;