// /components/settings/CustomCSS.js

import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosConfig';

const CustomCSS = () => {
  const [customCSS, setCustomCSS] = useState('');

  useEffect(() => {
    fetchCustomCSS();
  }, []);

  const fetchCustomCSS = async () => {
    try {
      const response = await axiosInstance.get('/settings/custom-css');
      setCustomCSS(response.data.css);
    } catch (error) {
      console.error('Error fetching custom CSS:', error);
    }
  };

  const handleCSSChange = (e) => {
    setCustomCSS(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.put('/settings/custom-css', { css: customCSS });
      alert('Custom CSS updated successfully!');
    } catch (error) {
      console.error('Error updating custom CSS:', error);
      alert('Failed to update custom CSS. Please try again.');
    }
  };

  return (
    <div className="custom-css">
      <h3>Custom CSS</h3>
      <form onSubmit={handleSubmit}>
        <textarea
          value={customCSS}
          onChange={handleCSSChange}
          rows={20}
          cols={80}
        />
        <button type="submit">Save Changes</button>
      </form>
    </div>
  );
};

export default CustomCSS;