// File: frontend/src/components/users/RoleList.js

import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosConfig';
import './RoleList.css';
import { Link } from 'react-router-dom';

const RoleList = () => {
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        fetchRoles();
    }, []);

    const fetchRoles = async () => {
        try {
            const response = await axiosInstance.get('roles/');
            setRoles(response.data);
        } catch (error) {
            console.error("There was an error fetching the roles!", error);
        }
    };

    const handleDeleteRole = async (roleId) => {
        try {
            await axiosInstance.delete(`roles/${roleId}/`);
            fetchRoles(); // Refresh the list
        } catch (error) {
            console.error("There was an error deleting the role!", error);
        }
    };

    return (
        <div>
            <h1>Roles</h1>
            <Link to="/create-role" className="btn btn-primary">Create New Role (+)</Link>
            <table>
                <thead>
                    <tr>
                        <th>Role Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {roles.map(role => (
                        <tr key={role.id}>
                            <td>{role.name}</td>
                            <td>
                                <button onClick={() => handleDeleteRole(role.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default RoleList;