import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosConfig';
import Editor from '../common/Editor';

const CreatePage = ({ fetchPages }) => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);

    const handleSave = async (title, content) => {
        if (!user) {
            alert("User not authenticated.");
            navigate('/login');
            return;
        }

        const data = new FormData();
        data.append('title', title);
        data.append('content', content);

        try {
            const response = await axiosInstance.post('pages/', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            if (response.status === 201) {
                alert('Content saved!');
                if (typeof fetchPages === 'function') {
                    fetchPages();
                }
                navigate('/pages');
            } else {
                alert('Error saving content.');
            }
        } catch (error) {
            console.error('Error:', error.response?.data || error.message);
            alert('Error saving content.');
        }
    };

    return (
        <div className="create-page-container">
            <h1 className="create-page h1">Create New Page</h1>
            <Editor onSave={handleSave} />
        </div>
    );
};

export default CreatePage;