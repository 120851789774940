import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosConfig';
import { Link } from 'react-router-dom';
import './ListBlogs.css';

const ListBlogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    useEffect(() => {
        fetchBlogs();
    }, []);

    const fetchBlogs = async () => {
        try {
            const response = await axiosInstance.get('blogs/');
            setBlogs(response.data);
        } catch (error) {
            console.error("There was an error fetching the blogs!", error);
        }
    };

    const handleToggleStatus = async (id) => {
        const blog = blogs.find(blog => blog.id === id);
        try {
            const response = await axiosInstance.patch(`blogs/${id}/`, { is_published: !blog.is_published });
            setBlogs(blogs.map(b => b.id === id ? { ...b, is_published: response.data.is_published } : b));
        } catch (error) {
            console.error("There was an error updating the blog status!", error);
        }
    };

    const handleDeleteBlog = async (id) => {
        try {
            await axiosInstance.delete(`blogs/${id}/`);
            setBlogs(blogs.filter(blog => blog.id !== id));
        } catch (error) {
            console.error("There was an error deleting the blog!", error);
        }
    };

    const handleOrderChange = async (id, change) => {
        const blog = blogs.find(blog => blog.id === id);
        const newOrder = Math.max(0, (blog.order || 0) + change);
        try {
            const response = await axiosInstance.patch(`blogs/${id}/`, { order: newOrder });
            setBlogs(blogs.map(b => b.id === id ? { ...b, order: response.data.order } : b));
        } catch (error) {
            console.error("There was an error updating the blog order!", error);
        }
    };

    const sortBlogs = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedBlogs = [...blogs].sort((a, b) => {
            if (key === 'created_at' || key === 'updated_at') {
                return direction === 'ascending' 
                    ? new Date(a[key]) - new Date(b[key])
                    : new Date(b[key]) - new Date(a[key]);
            }
            if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
            return 0;
        });

        setBlogs(sortedBlogs);
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '↑' : '↓';
        }
        return '';
    };

    return (
        <div className="list-blogs-container">
            <h1>Blogs</h1>
            <Link to="/create-blog" className="btn btn-primary create-blog-btn">Create New Blog (+)</Link>
            <table className="blog-table">
                <thead>
                    <tr>
                        <th onClick={() => sortBlogs('order')}>Order {getSortIcon('order')}</th>
                        <th onClick={() => sortBlogs('title')}>Titles {getSortIcon('title')}</th>
                        <th onClick={() => sortBlogs('is_published')}>Status {getSortIcon('is_published')}</th>
                        <th onClick={() => sortBlogs('created_at')}>Created Date {getSortIcon('created_at')}</th>
                        <th onClick={() => sortBlogs('updated_at')}>Last Modified Date {getSortIcon('updated_at')}</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {blogs.map(blog => (
                        <tr key={blog.id}>
                            <td>
                                <button onClick={() => handleOrderChange(blog.id, -1)} className="order-btn">-</button>
                                <span className="order-value">{blog.order || 0}</span>
                                <button onClick={() => handleOrderChange(blog.id, 1)} className="order-btn">+</button>
                            </td>
                            <td>{blog.title}</td>
                            <td>
                                <label className="switch">
                                    <input 
                                        type="checkbox" 
                                        checked={blog.is_published} 
                                        onChange={() => handleToggleStatus(blog.id)} 
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </td>
                            <td>{new Date(blog.created_at).toLocaleDateString()}</td>
                            <td>{new Date(blog.updated_at).toLocaleDateString()}</td>
                            <td>
                                <Link to={`/edit-blog/${blog.id}`} className="btn btn-secondary edit-btn">Edit</Link>
                                <button onClick={() => handleDeleteBlog(blog.id)} className="btn btn-danger delete-btn">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ListBlogs;