// /components/settings/SiteConfiguration.js

import React from 'react';
import GeneralSettings from './GeneralSettings';

const SiteConfiguration = () => {
  return (
    <div className="site-configuration">
      <h2>Site Configuration</h2>
      <GeneralSettings />
    </div>
  );
};

export default SiteConfiguration;