// File: frontend/src/App.js

import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setToken } from './redux/slices/userSlice';
import axiosInstance from './axiosConfig';
import ViewPage from './components/pages/ViewPage';
import BlogMainPage from './components/blogs/BlogMainPage';
import Login from './components/Login';
import Header from './components/Header';
import Dashboard from './components/Dashboard';
import ListPages from './components/pages/ListPages';
import CreatePage from './components/pages/CreatePage';
import EditPage from './components/pages/EditPage';
import CreateSubPage from './components/pages/CreateSubPage';
import ListBlogs from './components/blogs/ListBlogs';
import CreateBlog from './components/blogs/CreateBlog';
import EditBlog from './components/blogs/EditBlog';
import CreateSubBlog from './components/blogs/CreateSubBlog';
import ViewBlog from './components/blogs/ViewBlog';
import UserList from './components/users/ListUsers';
import CreateUser from './components/users/CreateUser';
import EditUser from './components/users/EditUser';
import RoleList from './components/users/RoleList';
import CreateRole from './components/users/CreateRole';
import GeneralSettings from './components/settings/GeneralSettings';
import ThemeSelection from './components/settings/ThemeSelection';
import CustomCSS from './components/settings/CustomCSS';
import Settings from './components/settings/Settings';
import ChangePassword from './components/users/ChangePassword';


const PrivateRoute = ({ children }) => {
  const { user } = useSelector((state) => state.user);
  return user ? children : <Navigate to="/login" replace />;
};

const App = () => {
  const [pages, setPages] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      dispatch(setToken(token));
    }
  }, [dispatch]);

  const fetchPages = async () => {
    try {
      const response = await axiosInstance.get('pages/');
      setPages(response.data);
    } catch (error) {
      console.error('Error fetching pages:', error);
    }
  };

  const fetchBlogs = async () => {
    try {
      const response = await axiosInstance.get('blogs/');
      setBlogs(response.data);
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };

  useEffect(() => {
    fetchPages();
    fetchBlogs();
  }, []);

  return (
    <div className="app-container">
      <Header fetchPages={fetchPages} fetchBlogs={fetchBlogs} />
      <main className="main-content">
        <Routes>
          <Route path="/" element={<BlogMainPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/pages" element={<PrivateRoute><ListPages pages={pages} fetchPages={fetchPages} /></PrivateRoute>} />
          <Route path="/page/:id" element={<ViewPage />} />
          <Route path="/create-page" element={<PrivateRoute><CreatePage fetchPages={fetchPages} /></PrivateRoute>} />
          <Route path="/edit-page/:id" element={<PrivateRoute><EditPage fetchPages={fetchPages} /></PrivateRoute>} />
          <Route path="/create-subpage" element={<PrivateRoute><CreateSubPage fetchPages={fetchPages} /></PrivateRoute>} />
          <Route path="/blogs" element={<PrivateRoute><ListBlogs blogs={blogs} fetchBlogs={fetchBlogs} /></PrivateRoute>} />
          <Route path="/blog/:id" element={<ViewBlog />} />
          <Route path="/create-blog" element={<PrivateRoute><CreateBlog fetchBlogs={fetchBlogs} /></PrivateRoute>} />
          <Route path="/edit-blog/:id" element={<PrivateRoute><EditBlog fetchBlogs={fetchBlogs} /></PrivateRoute>} />
          <Route path="/create-subblog" element={<PrivateRoute><CreateSubBlog fetchBlogs={fetchBlogs} /></PrivateRoute>} />
          <Route path="/users" element={<PrivateRoute><UserList /></PrivateRoute>} />
          <Route path="/change-password/:userId" element={<ChangePassword />} />
          <Route path="/create-user" element={<PrivateRoute><CreateUser /></PrivateRoute>} />
          <Route path="/edit-user/:userId" element={<PrivateRoute><EditUser /></PrivateRoute>} />
          <Route path="/roles" element={<PrivateRoute><RoleList /></PrivateRoute>} />
          <Route path="/create-role" element={<PrivateRoute><CreateRole /></PrivateRoute>} />
          <Route path="/site-config" element={<PrivateRoute><GeneralSettings /></PrivateRoute>} />
          <Route path="/theme-selection" element={<PrivateRoute><ThemeSelection /></PrivateRoute>} />
          <Route path="/custom-css" element={<PrivateRoute><CustomCSS /></PrivateRoute>} />
          <Route path="/settings/*" element={<PrivateRoute><Settings /></PrivateRoute>} />
        </Routes>
      </main>
    </div>
  );
};

export default App;