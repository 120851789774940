// /components/settings/GeneralSettings.js

import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosConfig';

const GeneralSettings = () => {
  const [settings, setSettings] = useState({
    site_name: '',
    site_description: '',
    admin_email: ''
  });

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await axiosInstance.get('/settings/general');
      setSettings(response.data);
    } catch (error) {
      console.error('Error fetching general settings:', error);
    }
  };

  const handleChange = (e) => {
    setSettings({ ...settings, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.put('/settings/general', settings);
      alert('Settings updated successfully!');
    } catch (error) {
      console.error('Error updating general settings:', error);
      alert('Failed to update settings. Please try again.');
    }
  };

  return (
    <div className="general-settings">
      <h3>General Settings</h3>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="site_name">Site Name:</label>
          <input
            type="text"
            id="site_name"
            name="site_name"
            value={settings.site_name}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="site_description">Site Description:</label>
          <textarea
            id="site_description"
            name="site_description"
            value={settings.site_description}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="admin_email">Admin Email:</label>
          <input
            type="email"
            id="admin_email"
            name="admin_email"
            value={settings.admin_email}
            onChange={handleChange}
          />
        </div>
        <button type="submit">Save Changes</button>
      </form>
    </div>
  );
};

export default GeneralSettings;