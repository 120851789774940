// /components/settings/Appearance.js

import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import ThemeSelection from './ThemeSelection';
import CustomCSS from './CustomCSS';

const Appearance = () => {
  return (
    <div className="appearance">
      <h2>Appearance</h2>
      <nav>
        <ul>
          <li><Link to="theme">Theme Selection</Link></li>
          <li><Link to="css">Custom CSS</Link></li>
        </ul>
      </nav>

      <Routes>
        <Route path="theme" element={<ThemeSelection />} />
        <Route path="css" element={<CustomCSS />} />
      </Routes>
    </div>
  );
};

export default Appearance;