import React, { useState } from 'react';
import axios from 'axios';

const CreateRole = () => {
    const [roleName, setRoleName] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const csrfToken = getCookie('csrftoken');
        try {
            const response = await axios.post('http://127.0.0.1:8000/api/roles/', { name: roleName }, {
                headers: {
                    'X-CSRFToken': csrfToken
                },
                withCredentials: true
            });
            console.log('Role created successfully:', response.data);
            setSuccessMessage('Role created successfully');
            setRoleName('');
        } catch (error) {
            console.error('There was an error creating the role!', error);
            setError('There was an error creating the role!');
        }
    };

    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    return (
        <div>
            <h1>Create Role</h1>
            {successMessage && <p className="success-message">{successMessage}</p>}
            {error && <p className="error-message">{error}</p>}
            <form onSubmit={handleSubmit}>
                <label>
                    Role Name:
                    <input
                        type="text"
                        value={roleName}
                        onChange={(e) => setRoleName(e.target.value)}
                    />
                </label>
                <button type="submit">Create Role</button>
            </form>
        </div>
    );
};

export default CreateRole;
