import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../axiosConfig';

const ViewPage = () => {
    const [page, setPage] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchPage = async () => {
            try {
                const response = await axiosInstance.get(`pages/${id}/`);
                setPage(response.data);
            } catch (error) {
                console.error("Error fetching page:", error);
                // If unauthorized, try fetching without authentication
                if (error.response && error.response.status === 401) {
                    try {
                        const publicResponse = await axiosInstance.get(`pages/${id}/`, { headers: { Authorization: '' } });
                        setPage(publicResponse.data);
                    } catch (publicError) {
                        console.error("Error fetching public page:", publicError);
                    }
                }
            }
        };

        fetchPage();
    }, [id]);

    if (!page) {
        return <div>Loading...</div>;
    }

    return (
        <div>
          <h1 className="title">{page.title}</h1>
          <div className="view-page-container" dangerouslySetInnerHTML={{ __html: page.content }} />
        </div>
      );
};

export default ViewPage;