import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axiosInstance from '../../axiosConfig';
import { useNavigate } from 'react-router-dom';
import Editor from '../common/Editor';

const CreateBlog = () => {
    const [createdDate, setCreatedDate] = useState('');
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);

    const handleSave = async (title, content) => {
        if (!user) {
            alert("User not authenticated.");
            navigate('/login');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('content', content);
            formData.append('created_at', createdDate || new Date().toISOString());

            const response = await axiosInstance.post('blogs/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            if (response.status === 201) {
                navigate('/blogs');
            }
        } catch (error) {
            console.error('Error creating blog:', error.response?.data || error.message);
        }
    };

    return (
        <div className="create-blog-container">
            <h1>Create Blog</h1>
            <input
                type="datetime-local"
                value={createdDate}
                onChange={(e) => setCreatedDate(e.target.value)}
            />
            <Editor
                initialTitle=""
                initialContent=""
                onSave={handleSave}
            />
        </div>
    );
};

export default CreateBlog;
