// File: frontend/src/components/common/Editor.js

import React, { useEffect, useRef, useState, useCallback } from 'react';
import axiosInstance from '../../axiosConfig';
import EditorToolbar from './EditorToolbar';
import './Editor.css';

const Editor = ({ initialTitle, initialContent, onSave, allowImageResize }) => {
    const [title, setTitle] = useState(initialTitle || '');
    const [showTableMenu, setShowTableMenu] = useState(false);
    const [tableRows, setTableRows] = useState(2);
    const [tableCols, setTableCols] = useState(2);
    const [showImagePlacementMenu, setShowImagePlacementMenu] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const editorRef = useRef(null);
    const titleRef = useRef(null);
    const [history, setHistory] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(-1);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageWidth, setImageWidth] = useState('');
    const [imageHeight, setImageHeight] = useState('');
    const [isInitialized, setIsInitialized] = useState(false);


    const saveToHistory = useCallback(() => {
        setHistory(prevHistory => {
            const newHistory = prevHistory.slice(0, historyIndex + 1);
            newHistory.push(editorRef.current.innerHTML);
            setHistoryIndex(newHistory.length - 1);
            return newHistory;
        });
    }, [historyIndex]);

    const undo = useCallback(() => {
        if (historyIndex > 0) {
            setHistoryIndex(prevIndex => {
                editorRef.current.innerHTML = history[prevIndex - 1];
                return prevIndex - 1;
            });
        }
    }, [history, historyIndex]);

    const redo = useCallback(() => {
        if (historyIndex < history.length - 1) {
            setHistoryIndex(prevIndex => {
                editorRef.current.innerHTML = history[prevIndex + 1];
                return prevIndex + 1;
            });
        }
    }, [history, historyIndex]);

    const selectImage = useCallback((img) => {
        setSelectedImage(img);
        setImageWidth(img.width);
        setImageHeight(img.height);
    }, []);

    const showResizers = useCallback((wrapper) => {
        const allResizers = editorRef.current.querySelectorAll('.resizer');
        allResizers.forEach(resizer => resizer.style.display = 'none');

        const allWrappers = editorRef.current.querySelectorAll('.img-wrapper');
        allWrappers.forEach(w => w.classList.remove('active'));

        const resizers = wrapper.querySelectorAll('.resizer');
        resizers.forEach(resizer => resizer.style.display = 'block');
        wrapper.classList.add('active');

        const img = wrapper.querySelector('img');
        selectImage(img);
    }, [selectImage]);

    const initResize = useCallback((e, resizer, wrapper) => {
        e.stopPropagation();
        e.preventDefault();
        const img = wrapper.querySelector('img');
        const startX = e.clientX;
        const startY = e.clientY;
        const startWidth = img.clientWidth;
        const startHeight = img.clientHeight;

        const doDrag = (e) => {
            e.stopPropagation();
            e.preventDefault();
            const dx = e.clientX - startX;
            const dy = e.clientY - startY;

            if (resizer.classList.contains('bottom-right')) {
                img.style.width = `${startWidth + dx}px`;
                img.style.height = `${startHeight + dy}px`;
            } else if (resizer.classList.contains('bottom-left')) {
                img.style.width = `${startWidth - dx}px`;
                img.style.height = `${startHeight + dy}px`;
                wrapper.style.marginLeft = `${dx}px`;
            } else if (resizer.classList.contains('top-right')) {
                img.style.width = `${startWidth + dx}px`;
                img.style.height = `${startHeight - dy}px`;
                wrapper.style.marginTop = `${dy}px`;
            } else if (resizer.classList.contains('top-left')) {
                img.style.width = `${startWidth - dx}px`;
                img.style.height = `${startHeight - dy}px`;
                wrapper.style.marginLeft = `${dx}px`;
                wrapper.style.marginTop = `${dy}px`;
            }
            
            setImageWidth(img.width);
            setImageHeight(img.height);
        };

        const stopDrag = () => {
            document.removeEventListener('mousemove', doDrag, false);
            document.removeEventListener('mouseup', stopDrag, false);
            saveToHistory();
        };

        document.addEventListener('mousemove', doDrag, false);
        document.addEventListener('mouseup', stopDrag, false);
    }, [saveToHistory]);

    const addResizers = useCallback((wrapper) => {
        wrapper.querySelectorAll('.resizer').forEach(resizer => resizer.remove());

        const resizers = ['top-left', 'top-right', 'bottom-left', 'bottom-right'];

        resizers.forEach(pos => {
            const resizer = document.createElement('div');
            resizer.className = `resizer ${pos}`;
            resizer.style.display = 'none';
            resizer.addEventListener('mousedown', (e) => initResize(e, resizer, wrapper));
            wrapper.appendChild(resizer);
        });

        wrapper.addEventListener('click', (e) => {
            e.stopPropagation();
            showResizers(wrapper);
        });
    }, [showResizers, initResize]);

    const hideResizers = useCallback(() => {
        const resizers = editorRef.current.querySelectorAll('.resizer');
        resizers.forEach(resizer => resizer.style.display = 'none');
        const activeWrapper = editorRef.current.querySelector('.img-wrapper.active');
        if (activeWrapper) {
            activeWrapper.classList.remove('active');
        }
        setSelectedImage(null);
        setImageWidth('');
        setImageHeight('');
    }, []);

    const handleImageResize = useCallback(() => {
        if (selectedImage && imageWidth && imageHeight) {
            selectedImage.style.width = `${imageWidth}px`;
            selectedImage.style.height = `${imageHeight}px`;
            saveToHistory();
        }
    }, [selectedImage, imageWidth, imageHeight, saveToHistory]);

    const execCmd = useCallback((command, value = null) => {
        document.execCommand(command, false, value);
        saveToHistory();
    }, [saveToHistory]);

    const placeImage = useCallback((imgSrc, placement) => {
        const editor = editorRef.current;
        const img = document.createElement('img');
        img.src = imgSrc;
        img.style.maxWidth = '100%';
        img.contentEditable = false;
        
        const wrapper = document.createElement('div');
        wrapper.className = 'img-wrapper';
        wrapper.contentEditable = false;
        wrapper.appendChild(img);
        
        switch (placement) {
            case 'top-left':
                wrapper.style.float = 'left';
                wrapper.style.marginRight = '10px';
                wrapper.style.marginBottom = '10px';
                img.style.width = '250px';
                img.style.height = '200px';
                editor.insertBefore(wrapper, editor.firstChild);
                break;
            case 'top-right':
                wrapper.style.float = 'right';
                wrapper.style.marginLeft = '10px';
                wrapper.style.marginBottom = '10px';
                img.style.width = '250px';
                img.style.height = '200px';
                editor.insertBefore(wrapper, editor.firstChild);
                break;
            case 'banner':
                wrapper.classList.add('banner');
                wrapper.style.width = '100%';
                img.style.width = '100%';
                img.style.height = '329px';
                img.style.objectFit = 'cover';
                editor.insertBefore(wrapper, editor.firstChild);
                break;
            case 'cursor':
                const selection = window.getSelection();
                if (selection.rangeCount > 0) {
                    const range = selection.getRangeAt(0);
                    range.insertNode(wrapper);
                } else {
                    editor.appendChild(wrapper);
                }
                break;
            default:
                console.warn(`Unexpected placement: ${placement}`);
                editor.appendChild(wrapper);
        }
        
        const br = document.createElement('br');
        if (wrapper.nextSibling) {
            editor.insertBefore(br, wrapper.nextSibling);
        } else {
            editor.appendChild(br);
        }
        addResizers(wrapper);
        saveToHistory();
    }, [addResizers, saveToHistory]);

    const uploadImage = useCallback((event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setShowImagePlacementMenu(true);
        }
    }, []);

    const handleImageUploadAndPlacement = useCallback(async (placement) => {
        if (!selectedFile) return;
    
        const formData = new FormData();
        formData.append('image', selectedFile);
    
        try {
            const response = await axiosInstance.post('media/upload/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.status === 201) {
                placeImage(response.data.image_url, placement);
            } else {
                alert('Error uploading image.');
            }
        } catch (error) {
            console.error('Error:', error.response?.data || error.message);
            alert('Error uploading image. Please try again or check your authentication.');
        }
    
        setShowImagePlacementMenu(false);
        setSelectedFile(null);
    }, [selectedFile, placeImage]);

    const wrapTextAroundImage = useCallback((alignment) => {
        const activeWrapper = editorRef.current.querySelector('.img-wrapper.active');
        if (activeWrapper) {
            activeWrapper.style.float = alignment;
            activeWrapper.style.margin = alignment === 'left' ? '0 10px 10px 0' : '0 0 10px 10px';
            saveToHistory();
        }
    }, [saveToHistory]);

    const insertTable = useCallback(() => {
        setShowTableMenu(true);
    }, []);

    const handleTableCreation = useCallback(() => {
        const table = document.createElement('table');
        table.style.width = '100%';
        for (let i = 0; i < tableRows; i++) {
            const tr = table.insertRow();
            for (let j = 0; j < tableCols; j++) {
                const td = tr.insertCell();
                td.appendChild(document.createTextNode(' '));
                td.style.border = '1px solid black';
            }
        }
        editorRef.current.appendChild(table);
        setShowTableMenu(false);
        saveToHistory();
    }, [tableRows, tableCols, saveToHistory]);

    const deleteSelectedImage = useCallback(() => {
        if (selectedImage) {
            const wrapper = selectedImage.closest('.img-wrapper');
            if (wrapper) {
                wrapper.remove();
                setSelectedImage(null);
                setImageWidth('');
                setImageHeight('');
                saveToHistory();
            }
        }
    }, [selectedImage, saveToHistory]);

    const handleSave = useCallback(() => {
        const content = editorRef.current.innerHTML;
        onSave(title, content);
    }, [title, onSave]);

    const addImageListeners = useCallback(() => {
        const imgWrappers = editorRef.current.querySelectorAll('.img-wrapper');
        imgWrappers.forEach(wrapper => {
            wrapper.removeEventListener('click', wrapper.clickHandler);
            wrapper.clickHandler = (e) => {
                e.stopPropagation();
                showResizers(wrapper);
            };
            wrapper.addEventListener('click', wrapper.clickHandler);
        });
    }, [showResizers]);

    const preserveFormatting = useCallback(() => {
        const editor = editorRef.current;
        const paragraphs = editor.querySelectorAll('p');
        paragraphs.forEach(p => {
            if (!p.style.lineHeight) {
                p.style.lineHeight = '1.5';
            }
            if (!p.style.marginBottom) {
                p.style.marginBottom = '1em';
            }
        });
    }, []);

    const handleSetLetterSpacing = useCallback((spacing) => {
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            if (editorRef.current.contains(range.commonAncestorContainer)) {
                // If the range is collapsed (cursor position), expand it to include the word
                if (range.collapsed) {
                    const word = getWordAtCursor(range.startContainer, range.startOffset);
                    if (word) {
                        range.setStart(word.startContainer, word.startOffset);
                        range.setEnd(word.endContainer, word.endOffset);
                    }
                }
    
                // Create a new span element with the desired letter spacing
                const span = document.createElement('span');
                span.style.letterSpacing = spacing;
    
                // Extract the contents of the range
                const contents = range.extractContents();
                
                // If spacing is 'normal', remove any existing letter-spacing styles
                if (spacing === 'normal') {
                    const innerSpans = contents.querySelectorAll('span[style*="letter-spacing"]');
                    innerSpans.forEach(innerSpan => {
                        const parent = innerSpan.parentNode;
                        while (innerSpan.firstChild) {
                            parent.insertBefore(innerSpan.firstChild, innerSpan);
                        }
                        parent.removeChild(innerSpan);
                    });
                    range.insertNode(contents);
                } else {
                    // Append the extracted contents to the new span
                    span.appendChild(contents);
                    // Insert the new span into the document
                    range.insertNode(span);
                }
    
                // Restore the selection
                selection.removeAllRanges();
                selection.addRange(range);
    
                saveToHistory();
            }
        }
    }, [saveToHistory]);

     // Helper function to get the word at the cursor position
     const getWordAtCursor = (node, offset) => {
        const text = node.textContent;
        const before = text.slice(0, offset).search(/\S+$/);
        const after = text.slice(offset).search(/\s/);
        if (before === -1 && after === -1) return null;
        const startOffset = before === -1 ? 0 : before;
        const endOffset = after === -1 ? text.length : offset + after;
        return {
            startContainer: node,
            endContainer: node,
            startOffset,
            endOffset
        };
    };


const setLineHeight = useCallback((height) => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        
        // Check if the selection is within the editor
        if (!editorRef.current.contains(range.commonAncestorContainer)) {
            return;
        }

        // If the selection is collapsed (cursor position), apply to the current block
        if (range.collapsed) {
            let currentNode = range.startContainer;
            while (currentNode !== editorRef.current) {
                if (currentNode.nodeType === Node.ELEMENT_NODE) {
                    currentNode.style.lineHeight = height;
                    break;
                }
                currentNode = currentNode.parentNode;
            }
        } else {
            // For non-collapsed selections, iterate through the selected nodes
            const treeWalker = document.createTreeWalker(
                range.commonAncestorContainer,
                NodeFilter.SHOW_ELEMENT,
                {
                    acceptNode: function(node) {
                        return range.intersectsNode(node) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
                    }
                }
            );

            let currentNode = treeWalker.nextNode();
            while (currentNode) {
                currentNode.style.lineHeight = height;
                currentNode = treeWalker.nextNode();
            }
        }
    } else {
        // If no selection, apply to the whole editor
        editorRef.current.style.lineHeight = height;
    }
}, []);

    useEffect(() => {
        if (!isInitialized && editorRef.current && initialContent) {
            editorRef.current.innerHTML = initialContent;
            
            const imgWrappers = editorRef.current.querySelectorAll('.img-wrapper');
            imgWrappers.forEach(wrapper => {
                addResizers(wrapper);
            });

            addImageListeners();
            preserveFormatting();
            saveToHistory();
            setIsInitialized(true);
        }
    }, [initialContent, addResizers, addImageListeners, preserveFormatting, saveToHistory, isInitialized]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (editorRef.current && !editorRef.current.contains(event.target) && 
                !event.target.closest('.toolbar')) {
                hideResizers();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [hideResizers]);

    const handleEditorClick = useCallback((e) => {
        const clickedWrapper = e.target.closest('.img-wrapper');
        if (clickedWrapper) {
            showResizers(clickedWrapper);
        } else {
            hideResizers();
        }
    }, [showResizers, hideResizers]);
    
    useEffect(() => {
        const currentEditor = editorRef.current;
        if (currentEditor) {
            currentEditor.addEventListener('click', handleEditorClick);
        }
        return () => {
            if (currentEditor) {
                currentEditor.removeEventListener('click', handleEditorClick);
            }
        };
    }, [handleEditorClick]);

    useEffect(() => {
        const observer = new MutationObserver(() => {
            addImageListeners();
            preserveFormatting();
        });

        if (editorRef.current) {
            observer.observe(editorRef.current, { childList: true, subtree: true });
        }

        return () => observer.disconnect();
    }, [addImageListeners, preserveFormatting]);

    return (
        <div className="editor-container">
            <input
                type="text"
                className="title-input"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                ref={titleRef}
            />
            <EditorToolbar
                execCmd={execCmd}
                wrapTextAroundImage={wrapTextAroundImage}
                uploadImage={uploadImage}
                insertTable={insertTable}
                setLineHeight={setLineHeight}
                editorRef={editorRef}  // Add this line
                undo={undo}
                redo={redo}
                selectedImage={selectedImage}
                imageWidth={imageWidth}
                imageHeight={imageHeight}
                setImageWidth={setImageWidth}
                setImageHeight={setImageHeight}
                handleImageResize={handleImageResize}
                deleteSelectedImage={deleteSelectedImage}
                setLetterSpacing={handleSetLetterSpacing}
                handleSave={handleSave}

            />
            {showImagePlacementMenu && (
                <div className="image-placement-menu">
                    <button onClick={() => handleImageUploadAndPlacement('top-left')}>Top Left (250x200)</button>
                    <button onClick={() => handleImageUploadAndPlacement('top-right')}>Top Right (250x200)</button>
                    <button onClick={() => handleImageUploadAndPlacement('banner')}>Banner (1128x329)</button>
                    <button onClick={() => handleImageUploadAndPlacement('cursor')}>At Cursor (Original Size)</button>
                    <button onClick={() => setShowImagePlacementMenu(false)}>Cancel</button>
                </div>
            )}
            {showTableMenu && (
                <div className="table-menu">
                    <label>Rows:</label>
                    <input
                        type="number"
                        value={tableRows}
                        onChange={(e) => setTableRows(parseInt(e.target.value))}
                        min="1"
                    />
                    <label>Columns:</label>
                    <input
                        type="number"
                        value={tableCols}
                        onChange={(e) => setTableCols(parseInt(e.target.value))}
                        min="1"
                    />
                    <button onClick={handleTableCreation}>Create Table</button>
                </div>
            )}
            <div 
                id="editor" 
                ref={editorRef} 
                contentEditable="true" 
                onInput={() => {
                    saveToHistory();
                    preserveFormatting();
                }}
                dangerouslySetInnerHTML={{ __html: initialContent }}
                
            ></div>
            <button onClick={handleSave} title="Save">Save</button>
        </div>
    );
};

export default Editor;