// File: frontend/src/components/users/ListUsers.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../../axiosConfig';
import './ListUsers.css';

const ListUsers = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axiosInstance.get('users/');
            setUsers(response.data);
        } catch (error) {
            console.error('There was an error fetching the users!', error);
        }
    };

    const lockUser = async (userId) => {
        try {
            await axiosInstance.patch(`users/${userId}/lock/`);
            fetchUsers(); // Refresh the list
        } catch (error) {
            console.error('There was an error locking the user!', error);
        }
    };

    const deleteUser = async (userId) => {
        try {
            await axiosInstance.delete(`users/${userId}/`);
            fetchUsers(); // Refresh the list
        } catch (error) {
            console.error('There was an error deleting the user!', error);
        }
    };

    return (
        <div className="users-list-container">
            <h1>Users List</h1>
            <Link to="/create-user" className="btn btn-primary">Create User</Link>
            <table className="users-table">
                <thead>
                    <tr>
                        <th>User Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Created Date</th>
                        <th>Last Modified Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            <td>{user.username}</td>
                            <td>{user.email}</td>
                            <td>{user.role ? user.role : 'N/A'}</td>
                            <td>{user.date_joined ? new Date(user.date_joined).toLocaleString() : 'N/A'}</td>
                            <td>{user.last_modified ? new Date(user.last_modified).toLocaleString() : 'N/A'}</td>
                            <td className="action-buttons">
                                <button onClick={() => lockUser(user.id)} className="action-button lock-button">Lock</button>
                                <Link to={`/edit-user/${user.id}`} className="action-button edit-button">Edit</Link>
                                <Link to={`/change-password/${user.id}`} className="action-button change-password-button">Change Password</Link>
                                <button onClick={() => deleteUser(user.id)} className="action-button delete-button">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ListUsers;